// import React from "react"
import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import "../../styles/bh-due-diligence/bh-due-diligence.style.css"
import BHDueDiligenceModal from "../../components/bh-due-diligence-modal"
import useModalProps from "../../helpers/use-modal-props"
import "swiper/swiper.css"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper"

const dataVideos = [
  {
    firm_Due_Diligence_Videos: [
      {
        id: 2,
        src:
          "https://player.vimeo.com/video/1051490078?badge=0&autopause=0&player_id=0&app_id=58479",
        title: "Counterparty Onboarding",
        host:
          "With Stephane Liot, Trading COO",
      },
      {
        id: 0,
        src:
          "https://player.vimeo.com/video/914831332?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        title: "Introduction to Brevan Howard",
        host:
          "with Danny Bernheim, Senior Managing Director, Client Partnership Group",
      },
      {
        id: 1,
        src:
          "https://player.vimeo.com/video/910577587?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        title: "Introduction to Risk Management",
        host: "with Alex Assouline, Chief Risk Officer",
      },
    ],
    fund_Due_Diligence_Videos: [
      {
        id: 3,
        src:
          "https://player.vimeo.com/video/914830063?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        title: "Introduction to the Brevan Howard Master Fund",
        host:
          "with Danny Bernheim, Senior Managing Director, Client Partnership Group",
      },
      {
        id: 2,
        src:
          "https://player.vimeo.com/video/914829230?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        title: "Introduction to the Brevan Howard Alpha Strategies Master Fund",
        host:
          "with Danny Bernheim, Senior Managing Director, Client Partnership Group",
      },
      {
        id: 4,
        src:
          "https://player.vimeo.com/video/914411467?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        title: "Brevan Howard Alpha Strategies Fund Capital Allocation Process",
        host: "David Abbou - Head of BH Alpha Fund Trading",
      },
      {
        id: 5,
        src:
          "https://player.vimeo.com/video/920446862?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        title: "Introduction to the Pass-Through Fee Structure",
        host:
          "with Danny Bernheim, Senior Managing Director, Client Partnership Group",
      },
      
    ],
  },
]

function DueDiligenceVideos() {
  const duediligencevideosacf = useStaticQuery(graphql`
    query {
      wpgraphql {
        page(id: "cG9zdDoyMTg3") {
          id
          duediligencevideosacf {
            disclosuresModalData {
              content {
                fieldGroupName
                text
                title
              }
              fieldGroupName
              title
            }
            modalAcceptButtonLabel
            fieldGroupName
            modalCloseButtonLabel
            modalFooterText
          }
        }
      }
    }
  `)

  let initialOpen = false
  // const [initialOpen, setInitialOpen] = React.useState(null);

  /* USE SESSION */
  if (typeof window !== "undefined") {
    if (
      window.sessionStorage.length == 0 ||
      window.sessionStorage.getItem("bh-due-diligence") !==
        "accepted" /* ||
      window.localStorage.length == 0 ||
      window.localStorage.getItem("bh-disclosureNoticesStatus") !== "accepted" */
    ) {
      // console.log('do we have this value?', window.localStorage, (window.localStorage.getItem('bh-disclosureNoticesStatus') !== 'accepted'), window.localStorage.length == 0);
      // setInitialOpen(false);
      initialOpen = false
    }
  }

  const { modalProps } = useModalProps({ initialOpen: initialOpen })
  const { modalStatus } = modalProps

  const [swiper, setSwiper] = React.useState(null)
  const [swiperv2, setSecondSwiper] = React.useState(null)
  //console.log("swiperindex", swiperindex)

  const nexto = () => {
    swiper.slideNext()
  }
  const prevto = () => {
    swiper.slidePrev()
  }

  const nextov2 = () => {
    swiperv2.slideNext()
  }
  const prevtov2 = () => {
    swiperv2.slidePrev()
  }

  function swiperto(item) {
    alert(item)
    // swiper.slideTo(item);
  }

  const x = (x, y) => {
    return x * y
  }

  return (
    <Layout>
      <SEO title="Due Diligence Videos" />
      <div className="video-slider-wrapper dd-intro-bg">
        <div className="vs-container-wrap">
          <div className="container">
            <h3 className="txt-white">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 704.75 77.83"
              >
                <g id="Layer_1-2" data-name="Layer 1">
                  <g>
                    <path
                      className="cls-1"
                      d="m19.64,57.5l2.99-.4c3.22-.46,3.56-1.38,3.56-3.85v-28.66c0-2.47-.34-3.39-3.56-3.85l-2.99-.4v-.92h17.69c7.41,0,14.82,2.13,14.82,9.02,0,5.46-4.94,8.38-11.6,9.31v.23c7.98.52,13.44,3.22,13.44,9.53,0,7.23-6.26,10.91-16.02,10.91h-18.33v-.92Zm17-20.04c6.26,0,8.73-3.04,8.73-9.02,0-6.55-2.81-7.98-8.56-7.98h-4.25v17h4.08Zm-4.08,16.54c0,2.35,1.03,3.39,3.62,3.39h1.61c6.03,0,8.9-2.75,8.9-9.93,0-6.55-3.68-8.96-10.11-8.96h-4.02v15.51h0Z"
                    />
                    <path
                      className="cls-1"
                      d="m103.85,58.41c-3.56,0-4.02-.8-5.34-3.33l-7.75-15.16c-.46.06-.92.06-1.32.06-1.55,0-3.1-.06-4.31-.12v13.38c0,2.47.34,3.39,3.56,3.85l2.99.4v.92c-3.45,0-6.32-.06-9.7-.06s-6.32.06-9.76.06v-.92l2.99-.4c3.21-.46,3.56-1.38,3.56-3.85v-28.65c0-2.47-.34-3.39-3.56-3.85l-2.99-.4v-.92h16.94c10.22,0,16.71,2.81,16.71,10.17,0,5.57-4.88,8.38-9.48,9.31.75.86,1.61,2.07,2.64,3.9l5.17,9.36c1.78,3.27,2.47,4.31,5.05,4.88l2.07.46v.92h-7.47Zm-18.72-19.58c1.61.06,2.93.12,4.36.12,6.26,0,9.53-2.64,9.53-9.19,0-7.24-3.27-9.36-9.88-9.36h-4.02v18.43h0Z"
                    />
                    <path
                      className="cls-1"
                      d="m143.1,37.8c3.68,0,4.88-2.52,6.78-8.56h.98c-.06,3.04-.06,6.09-.06,9.13s0,6.09.06,9.13h-.98c-2.07-6.49-3.1-8.67-6.78-8.67h-4.19v15.16c0,2.35,1.03,3.39,3.62,3.39h3.68c6.66,0,8.27-2.47,12.06-12.57h1.15l-.63,13.61h-32.79v-.92l2.99-.4c3.22-.46,3.56-1.38,3.56-3.85v-28.66c0-2.47-.34-3.39-3.56-3.85l-2.99-.4v-.92h31.07l.63,12.46h-1.15c-3.44-8.79-5.05-11.43-10.68-11.43h-6.95v17.34h4.19,0Z"
                    />
                    <path
                      className="cls-1"
                      d="m181.22,26.54c-1.61-4.36-2.81-5.57-5.28-5.92l-2.13-.29v-.92c3.27.06,6.6.06,9.88.06s6.55,0,9.88-.06v.92l-3.27.4c-3.1.4-3.45,2.01-2.53,4.54l9.65,26.59,8.38-22.86c2.13-5.63,1.61-7.52-2.3-8.15l-2.81-.51v-.92c2.59.06,5.11.06,7.64.06s5.11,0,7.7-.06v.86l-2.18.58c-3.27.75-4.54,2.47-6.89,8.61l-8.5,22.51-2.3,6.6h-2.7l-12.23-32.04h-.01Z"
                    />
                    <path
                      className="cls-1"
                      d="m250.95,58.36c-3.21,0-5.97.06-9.25.06v-.92l3.27-.4c3.1-.4,2.99-2.01,2.01-4.54l-2.58-6.78h-15.1l-1.21,3.1c-2.18,5.68-2.18,7.41,1.72,8.09l2.87.52v.92c-2.47,0-4.42-.06-6.89-.06s-4.83.06-7.29.06v-.86l1.9-.52c3.04-.8,4.19-2.41,6.55-8.55l8.79-22.74,2.3-6.6h2.99l12.46,32.16c1.72,4.42,2.35,5.57,4.76,5.92l1.95.29v.92c-3.27,0-5.97-.06-9.25-.06h0Zm-21.19-13.78h14.18l-7.06-18.72-7.12,18.72h0Z"
                    />
                    <path
                      className="cls-1"
                      d="m279.89,24.13v26.02c0,4.99,1.03,6.43,4.13,6.95l2.35.4v.92c-2.58,0-4.59-.06-7.18-.06s-4.65.06-7.24.06v-.92l2.35-.4c3.16-.52,4.19-1.95,4.19-6.95v-25.56c0-2.47-.34-3.39-3.56-3.85l-2.99-.4v-.92h12.52l20.9,30.84v-22.57c0-5-1.04-6.43-4.19-6.95l-2.36-.4v-.92c2.59,0,4.65.06,7.24.06s4.59-.06,7.18-.06v.92l-2.35.4c-3.1.51-4.14,1.95-4.14,6.95v30.84h-3.62l-23.26-34.4h.03Z"
                    />
                    <path
                      className="cls-1"
                      d="m399.67,24.59c0-2.47-.34-3.39-3.56-3.85l-2.99-.4v-.92c3.45,0,6.32.06,9.76.06s6.26-.06,9.7-.06v.92l-2.99.4c-3.21.46-3.56,1.37-3.56,3.85v28.65c0,2.47.34,3.39,3.56,3.85l2.99.4v.92c-3.45,0-6.32-.06-9.7-.06s-6.32.06-9.76.06v-.92l2.99-.4c3.22-.46,3.56-1.38,3.56-3.85v-14.19h-18.2v14.19c0,2.47.34,3.39,3.56,3.85l2.93.4v.92c-3.39,0-6.26-.06-9.7-.06s-6.26.06-9.7.06v-.92l2.99-.4c3.21-.46,3.56-1.38,3.56-3.85v-28.65c0-2.47-.34-3.39-3.56-3.85l-2.99-.4v-.92c3.45,0,6.32.06,9.7.06s6.32-.06,9.7-.06v.92l-2.93.4c-3.22.46-3.56,1.37-3.56,3.85v13.27h18.2v-13.27Z"
                    />
                    <path
                      className="cls-1"
                      d="m445.46,59.27c-10.74,0-19.47-8.04-19.47-20.33s8.73-20.39,19.47-20.39,19.35,8.04,19.35,20.39-8.67,20.33-19.35,20.33Zm0-.86c8.73,0,12.23-8.79,12.23-19.47s-3.45-19.53-12.23-19.53-12.35,8.79-12.35,19.53,3.39,19.47,12.35,19.47Z"
                    />
                    <path
                      className="cls-1"
                      d="m482.9,26.43c-1.38-4.19-2.47-5.46-5.05-5.8l-2.07-.29v-.92c3.27.06,6.6.06,9.88.06s6.55,0,9.88-.06v.92l-3.27.4c-3.22.4-3.56,2.3-2.87,4.54l7.69,25.44,8.96-31.53h2.99l10.39,31.53,6.43-21.65c1.89-6.26.57-7.64-3.1-8.27l-2.47-.46v-.92c2.58.06,5.11.06,7.7.06s5.11,0,7.64-.06v.86l-2.01.46c-3.39.74-4.71,2.58-6.55,8.73l-6.6,22.05-1.9,7.18h-2.93l-10.63-31.3-6.89,24.01-1.9,7.29h-2.87l-10.45-32.27h0Z"
                    />
                    <path
                      className="cls-1"
                      d="m572.51,58.36c-3.22,0-5.97.06-9.25.06v-.92l3.28-.4c3.1-.4,2.99-2.01,2.01-4.54l-2.58-6.78h-15.1l-1.21,3.1c-2.18,5.68-2.18,7.41,1.72,8.09l2.87.52v.92c-2.47,0-4.42-.06-6.89-.06s-4.83.06-7.29.06v-.86l1.9-.52c3.04-.8,4.19-2.41,6.55-8.55l8.79-22.74,2.3-6.6h2.99l12.46,32.16c1.72,4.42,2.35,5.57,4.76,5.92l1.95.29v.92c-3.28,0-5.97-.06-9.25-.06h-.01Zm-21.19-13.78h14.18l-7.06-18.72-7.12,18.72Z"
                    />
                    <path
                      className="cls-1"
                      d="m625.01,58.41c-3.56,0-4.02-.8-5.34-3.33l-7.75-15.16c-.46.06-.92.06-1.32.06-1.55,0-3.1-.06-4.31-.12v13.38c0,2.47.34,3.39,3.56,3.85l2.99.4v.92c-3.45,0-6.32-.06-9.7-.06s-6.32.06-9.76.06v-.92l2.99-.4c3.21-.46,3.56-1.38,3.56-3.85v-28.65c0-2.47-.34-3.39-3.56-3.85l-2.99-.4v-.92h16.94c10.22,0,16.71,2.81,16.71,10.17,0,5.57-4.88,8.38-9.48,9.31.75.86,1.61,2.07,2.64,3.9l5.17,9.36c1.78,3.27,2.47,4.31,5.05,4.88l2.07.46v.92h-7.47Zm-18.72-19.58c1.61.06,2.93.12,4.36.12,6.26,0,9.53-2.64,9.53-9.19,0-7.24-3.27-9.36-9.88-9.36h-4.02v18.43h.01Z"
                    />
                    <path
                      className="cls-1"
                      d="m646.29,57.5l2.99-.4c3.21-.46,3.56-1.38,3.56-3.85v-28.66c0-2.47-.34-3.39-3.56-3.85l-2.99-.4v-.92h17.92c10.85,0,20.9,5.51,20.9,19.53s-9.99,19.47-20.96,19.47h-17.86v-.92Zm17.86-.12c8.79,0,13.73-5.17,13.73-18.43s-5.05-18.49-13.67-18.49h-5v33.53c0,2.35,1.03,3.39,3.62,3.39h1.32Z"
                    />
                  </g>
                </g>
              </svg>
              <br />
              Due Diligence Videos
            </h3>
          </div>
        </div>
      </div>

      {/* FIRST SLIDER */}
      <div className="video-slider-wrapper v-white-bg pt-0">
        <div className="vs-container-wrap">
          <div className="container">
            {/* Here */}
            <h3 className="">Firm Due Diligence</h3>
            <div id="swiper-series" className="swiper-container">
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={50}
                slidesPerView={2}
                centeredSlides={true}
                onSlideChange={() => console.log("slide change")}
                onSwiper={swiper => {
                  //console.log("checking swiper slider", swiper)
                  // my_swiper = swiper;
                  setSwiper(swiper)
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  850: {
                    slidesPerView: 2,
                  },
                }}
              >
                {dataVideos[0].firm_Due_Diligence_Videos.map((video, index) => (
                  <SwiperSlide key={"ser-" + index}>
                    <p
                      className="txt-center"
                      style={{
                        color: "#a99e8f",
                        fontSize: "1.15rem",
                        fontStyle: "normal",
                        marginBottom: "0.75rem",
                      }}
                    >
                      {video.title}
                    </p>
                    <p
                      className="txt-center"
                      style={{ color: "#21293e", marginBottom: "1rem" }}
                    >
                      <em dangerouslySetInnerHTML={{ __html: video.host }}></em>
                    </p>
                    <div className="iframe-container">
                      <iframe
                        className="vimeo-iframe responsive-iframe"
                        src={video.src}
                        width="740"
                        height="460"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="v-slide-nav">
              <button onClick={prevto}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7.882"
                  height="14.622"
                  viewBox="0 0 7.882 14.622"
                >
                  <path
                    id="Path_73795"
                    data-name="Path 73795"
                    d="M0,0,6.794,6.794C6.443,6.443,13.589,0,13.589,0"
                    transform="translate(7.325 0.53) rotate(90)"
                    fill="none"
                    stroke="#5e5e5e"
                    strokeWidth="1.5"
                  />
                </svg>
              </button>

              <div className="vs-pagination">
                {dataVideos[0].firm_Due_Diligence_Videos.map((pagi2, index) => (
                  <span
                    className="slider-pagination-dot"
                    key={"Firm Due Diligence-" + index}
                    onClick={() => swiper.slideTo(index)}
                  ></span>
                ))}
              </div>
              <button onClick={nexto}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7.882"
                  height="14.622"
                  viewBox="0 0 7.882 14.622"
                >
                  <path
                    id="Path_68716"
                    data-name="Path 68716"
                    d="M0,6.795,6.795,0C6.443.352,13.59,6.795,13.59,6.795"
                    transform="translate(7.352 0.53) rotate(90)"
                    fill="none"
                    stroke="#5e5e5e"
                    strokeWidth="1.5"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* SECOND SLIDER */}
      <div className="video-slider-wrapper v-brown-bg pt-0">
        <div className="vs-container-wrap">
          <div className="container">
            <h3 className="txt-dark">Fund Due Diligence</h3>
            <div id="swiper-series" className="swiper-container">
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={50}
                slidesPerView={2}
                centeredSlides={true}
                onSlideChange={() => console.log("slide change")}
                onSwiper={swiper => {
                  setSecondSwiper(swiper)
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  850: {
                    slidesPerView: 2,
                  },
                }}
              >
                {dataVideos[0].fund_Due_Diligence_Videos.map((video, index) => (
                  <SwiperSlide key={"ser-" + index}>
                    <p
                      className="txt-center"
                      style={{
                        color: "#a99e8f",
                        fontSize: "1.15rem",
                        fontStyle: "normal",
                        marginBottom: "0.75rem",
                      }}
                    >
                      {video.title}
                    </p>
                    <p
                      className="txt-center txt-dark"
                      style={{ marginBottom: "1rem" }}
                    >
                      <em dangerouslySetInnerHTML={{ __html: video.host }}></em>
                    </p>

                    <div className="iframe-container">
                      <iframe
                        className="vimeo-iframe responsive-iframe"
                        src={video.src}
                        width="740"
                        height="460"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="v-slide-nav">
              <button onClick={prevtov2}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7.882"
                  height="14.622"
                  viewBox="0 0 7.882 14.622"
                >
                  <path
                    id="Path_73795"
                    data-name="Path 73795"
                    d="M0,0,6.794,6.794C6.443,6.443,13.589,0,13.589,0"
                    transform="translate(7.325 0.53) rotate(90)"
                    fill="none"
                    stroke="#5e5e5e"
                    strokeWidth="1.5"
                  />
                </svg>
              </button>
              <div className="vs-pagination">
                {dataVideos[0].fund_Due_Diligence_Videos.map((pagi2, index) => (
                  <span
                    className="slider-pagination-dot"
                    key={"Firm Due Diligence2-" + index}
                    onClick={() => swiperv2.slideTo(index)}
                  ></span>
                ))}
              </div>

              <button onClick={nextov2}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7.882"
                  height="14.622"
                  viewBox="0 0 7.882 14.622"
                >
                  <path
                    id="Path_68716"
                    data-name="Path 68716"
                    d="M0,6.795,6.795,0C6.443.352,13.59,6.795,13.59,6.795"
                    transform="translate(7.352 0.53) rotate(90)"
                    fill="none"
                    stroke="#5e5e5e"
                    strokeWidth="1.5"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <BHDueDiligenceModal
        modalProps={modalProps}
        digitalassetsacf={
          duediligencevideosacf.wpgraphql.page.duediligencevideosacf
        }
      />
    </Layout>
  )
}

export default DueDiligenceVideos
